<template>
  <div id="app">
    <div
      :class="[
        'post-page',
        { 'first-post-page': activeBlog.url == 'manifest-award-2022' },
      ]"
    >
      <div class="page-wrapper">
        <div class="page-content">
          <div class="section">
            <div class="wrapper">
              <div
                @click="$emit('openShareModal')"
                class="hover-link link desc share"
              >
                <img
                  class="img share-img"
                  alt="Share post"
                  src="./../assets/post/share/share.svg"
                />
                Share
              </div>
              <div class="inline-left">
                <h1 class="title">{{ activeBlog.title }}</h1>
              </div>
              <div
                class="inline-right"
                v-if="activeBlog.url == 'manifest-award-2022'"
              >
                <img alt="Manifest award" src="./../assets/minifest.png" />
              </div>
            </div>

            <!-- post 1 -->
            <div class="text" v-if="activeBlog.url == 'human-centered-design'">
              <div class="desc">
                <b>George Vasjagin</b> is the founder and creative director of
                UVIO Design Agency, and as their slogan says, Focused on
                Strategy. George has a distinct creative process resulting in
                simple, elegant, recognizable designs, with deep roots in the
                human-centered design approach. His process has led him to great
                success including the opportunity to do work for Fortune 500.
              </div>
              <div class="desc">
                He sat down with us for this AMA style interview, wearing his
                recognizable SF Giants cap, jeans and a hoodie to share more
                about his personal design process and his view on the
                Human-Centered Design approach.
              </div>
              <img alt="George Vasjagin" src="./../assets/post3/1233.jpg" />

              <h2 class="title-small">How did you first get into design?</h2>
              <div class="desc">
                Design was something I was always interested in. It began as a
                fun project for me when I designed a website for friends of my
                parents, and this later evolved with me doing all sorts of
                graphic design work. At the time, I still did not have any
                specific area of interest.
              </div>
              <div class="desc">
                This changed when the first iPhone came to the market in 2007. I
                soon realized that the future of UI was going to be mobile, and
                even though I could barely afford an iPhone back then, I decided
                on the future path of my career.
              </div>
              <div class="desc">
                I dove right in the deep end, learning all I could about UX/UI
                while doing all sorts of UX/UI designs. From there, some career
                choices have led me to Product Design and later to Design
                Leadership. This is the work I currently do, but I still prefer
                to do a lot of hands-on design work.
              </div>

              <h2 class="title-small">
                Can you share some of your more notable projects? Which one has
                had the most influence on you as a professional?
              </h2>
              <div class="desc">
                A good, albeit tricky question. I found I enjoyed more working
                on a smaller scale, short-term projects instead of building a
                career in a large company. From my point of view, it is more
                beneficial for a designer to learn by working on a dynamic range
                of projects and experiences.
              </div>
              <div class="desc">
                This led me to found my own design agency named “Awesomed” in
                2014. At that time, I had great luck working with some of the
                most notable Fortune500 companies such as PaloAlto Networks,
                Priceline, StateFarm and others. It allowed me to open the door
                to greater work opportunities and more importantly, helped me
                expand my skillset and design horizon.
              </div>

              <div class="desc">
                You can say I got a crash course in learning all about working
                with enterprises. This “forced” me to change some things in my
                work, most notably to be able to adapt to client’s wishes and
                learn to recognize their needs in advance. It was an
                educational, yet fun experience that ultimately led me to
                reshaping a lot of aspects of my design process and client
                approach.
              </div>
              <div class="desc">
                “Awesomed” was awesome (no pun intended) in teaching me a lot of
                the other aspects of running a design agency. It taught me what
                real leadership is and to recognize the importance of having a
                great talent in your team. Ultimately, the partnership didn’t
                work for me back then. Me and my co-founder had radically
                different views on how the agency should be run, and I quit.
              </div>
              <div class="desc">
                A little later, in 2018, I founded the Nova Design Agency (Now
                UVIO), forming a full-stack development and design team. We
                completely reinvented the format of how we work, positioning
                ourselves as a team, not as an agency. What’s the difference you
                ask? It’s simple — we’re much less formal and more casual (and
                more fun).
              </div>
              <h2 class="title-small">
                What are your strengths and weaknesses as a Product Designer?
              </h2>
              <div class="desc">
                I think one of my main strengths is my flexibility and the
                ability to quickly immerse myself in the product. I have this
                thing where I imagine the problem right to the core and start
                sketching possible solutions almost immediately. I’m more of a
                cut-to-the-chase kind of guy, cutting unnecessary corners and
                delving right into the design process.
              </div>
              <div class="desc">
                Also, I consider myself to be pretty good at building
                relationships with people, bridging gaps between the engineers,
                product designers, and the marketing team. It is my belief that
                each team within the organization should utilize as much of the
                design thinking as possible.
              </div>
              <div class="desc">
                Unfortunately for me, my main strength is also my weakness.
                Sometimes, my flexibility will get me wearing too many hats. I’m
                unable to deliver great results fast if I’m too constrained or
                constantly engaged in unnecessary meetings, or doing PM work. I
                find myself most productive when I’m excited about the product
                and have freedom to run my own design process.
              </div>
              <h2 class="title-small">What is your design process like?</h2>
              <div class="desc">
                If we take a look at the big picture, I think almost everything
                that could have been discovered/invented was already
                discovered/invented. The world we live in is so complex, dynamic
                and loud, that we usually find ourselves trying for ways to
                simplify things.
              </div>
              <div class="desc">
                This is also true for the design process. I have a simple
                philosophy — constantly learn from and talk to your users. By
                understanding their problems, you will be able to design the
                solution. I think the big mistake designers make is by making
                assumptions and being overly self-confident.
              </div>
              <div class="desc">
                It’s surprising how often a seemingly counterintuitive decision
                can be an effective solution. This is only evident when you are
                in close touch with your audience, do in-depth user research and
                listen to feedback. Add a little wireframing, rapid prototyping,
                testing, measuring, etc., and you have a product with a great
                user-friendly design.
              </div>
              <div class="desc">
                This is the base of the human-centered design process, which I’m
                a strong advocate for. This process is both how you think and
                what you do with it. Defined by three I’s: Inspiration, Ideation
                and Implementation, I find the human-centered design approach
                offers designers the right path for achieving great results.
              </div>
              <h2 class="title-small">
                What advice would you have for new designers?
              </h2>
              <div class="desc">
                Don’t over-think and overcomplicate things. Even though a
                fantastic visual style and beautifully crafted pixels could
                potentially help land you a client, it is not what will help you
                make a great product. Dedicate some time to truly understand the
                problem and think of clever ways to design a solution. Strive
                for simplicity instead of complexity.
              </div>
              <div class="desc">
                I sometimes test some of my designs (some basic interactions) on
                my three-year-old daughter. I show her the prototypes on my
                phone and look and see what she is trying to do. She is still
                learning to speak, but she already has no trouble navigating a
                phone. She finds her favorite cartoons on Netflix, launches
                games, or opens the camera and takes pictures.
              </div>
              <div class="desc">
                Technology comes naturally to today’s kids and if your designs
                pass the 3-year-old test, then I’d say you are on the right path
                for a broader audience. This does not mean that you should start
                running out the door in effort of finding a toddler to test your
                new app. It actually should serve as a constant reminder to
                strive for a more intuitive and simple interface in your design.
              </div>
              <div class="desc">
                The great Yoda master once said,
                <b>“The greatest teacher, failure is”.</b> The design is half
                art and half science. So, in order to continuously grow, each of
                us should find their path that works exclusively in their case.
                Exploration and experimentation are necessary. Never be afraid
                of failure, as failure offers invaluable lessons you can’t learn
                otherwise. Instead, learn from each failure and you will find
                your success.
              </div>
              <div class="desc">
                <b>Source article:</b
                ><a
                  href="https://medium.com/mux-design/human-centered-design-an-interview-with-george-vasjagin-the-founder-of-uvio-design-agency-b526790beb3e"
                  class="desc link"
                  target="__blank"
                >
                  https://medium.com/mux-design/human-centered-design-an-interview-with-george-vasjagin-the-founder-of-uvio-design-agency-b526790beb3e</a
                >
              </div>
            </div>

            <!-- post 2 -->
            <div class="text" v-if="activeBlog.url == 'manifest-award-2022'">
              <div class="desc">
                For over six years, UVIO has been helping companies build
                ingenious digital experiences for companies from all over the
                world. We serve as our client’s trusted
                <a href="https://clutch.co/web-designers/packages" class="link"
                  >design</a
                >
                and development for their A-to-Z digital design needs. In 2016,
                we hit the ground running, connecting with incredible brands
                that want to have meaningful solutions and impactful
                experiences. Today, we’re proudly celebrating a milestone that
                was helped unlocked by our clients’ awesome support.
              </div>
              <div class="desc">
                Just recently,
                <a
                  href="https://themanifest.com/lv/design/agencies#most-reviewed"
                  >The Manifest’s 2022 agency list</a
                >
                showed UVIO as one of the top reviewed and recommended B2B
                partners! According to their study, our team is Latvia’s finest
                when it comes to design and web development services!<br /><br />This
                is huge news for us! When UVIO was founded, we knew what kind of
                partner we wanted to be for our clients. We wanted to show them
                how capable we are so we can earn their invaluable trust. Our
                team set its eyes on the price, focusing on every opportunity
                we’ve been entrusted with.<br /><br />UVIO doesn’t skip a beat.
                Because of our approach and commitment, we’ve had the massive
                honor of working with amazing clients such as Popsters, Cuna,
                and Solertiae. Their wonderful trust helped us earn this new
                recognition.<br /><br />
              </div>
              <img alt="UVIO B2B" src="./../assets/post/shot.png" />
              <div class="desc">
                Moreover, The Manifest is an independent business resource
                that’s designed to help browsers better understand the B2B
                market. For their inaugural company awards, the platform
                showcases the strong relationships between service providers and
                their respective clients. The leaders chosen are the firms that
                received the highest number of stellar recommendations from
                their clients over the past year.<br /><br />The entire UVIO
                team is eternally grateful for every single one of our partners
                who’ve entrusted us with their exciting projects. Thank you so
                much for the generous support. Your trust means the world to us.
                <br /><br />Need professional, transparent, and quick solutions?
                Meet the UVIO team!
                <a href="https://www.uvio.co/" class="link">
                  Send us a message</a
                >
                and let us know more about what you need.<br /><br />
              </div>
            </div>

            <!-- post 3 -->
            <div class="text" v-if="activeBlog.url == 'design-system-uvio'">
              <img src="./../assets/post2/224.jpg" alt="UVIO Design System" />
              <div class="desc">
                If you’re working with a cross-functional team and want to avoid
                the tedious process of redesigning your products over again,
                then a Design System is for you.
              </div>
              <div class="desc">
                Our solid experience shows the majority of businesses view
                websites as a collection of pages. Pages offer a comprehensive
                picture of the design issue, including what content is being
                displayed, why it is being displayed, how it will be displayed,
                and how the user will interact with it. The development and
                design processes are guided by this idea. Each page is first
                designed by designers, then handed off to the development stage,
                who divide the mock-ups into tickets for developers in
                collaboration with a product manager.
              </div>
              <div class="desc">
                The page-based technique has many drawbacks while being widely
                used. Applications have a lot of dynamics too. The interface has
                to account for many different application states and responsive
                variants. Using static mock-ups to describe each of these phases
                is tiresome. Additionally, requirements frequently change as the
                development process gets underway. You come across previously
                undiscovered states, or there can be problems with
                implementation. The designs are still evolving in the interim.
                This approach's segregated structure makes it challenging to
                address concerns from either side. The team gradually begins to
                display a multitude of symptoms, including:

                <ul class="list num-list">
                  <li>Designers start to inhibit developers</li>
                  <li>Poor product decisions are made</li>
                  <li>The site's design deviates from its original intent.</li>
                  <li>
                    It takes a lot of time and effort to keep solving the same
                    issues.
                  </li>
                  <li>Maintenance becomes a nightmare</li>
                </ul>
              </div>
              <h2 class="title-small">What is the Design System?</h2>
              <div class="desc">
                The design system is very important in the branding world
                because when you have an established system that has been
                audited, your company can present a full scope of work to
                clients. This means that you are able to communicate your design
                decisions more effectively as well as reduce time in creating an
                overall interface.
              </div>
              <div class="desc">
                A Design System is an organized, maintainable, and living
                collection of your documents that help guide the design of your
                company. In better words, a Design System is a consistent and
                responsive library of reusable components, patterns, assets,
                templates, and guidelines that you will use to build an app or a
                website. The guidelines provide instructions on how components
                and tools should be used, so your apps or website look and
                behave the same across all devices all the time. The design
                system is a connected system, built on guidelines and shared
                principles, that helps create repeatable patterns and
                efficiencies across your organization.
              </div>
              <div class="desc">
                Design System's goal is to free you from the mundane work of
                creating various and unstructured design elements, while helping
                you create beautiful components, deliver them faster, and use
                them more effectively. You no longer have to spend hours
                creating designs from scratch, and instead can use Design
                System's code as the starting point for your next app or website
                redesign.
              </div>

              <img alt="Design system" src="./../assets/post2/222.jpg" />
              <h2 class="title-small">
                What are the Six Benefits of a Design System?
              </h2>
              <div class="desc">
                The primary benefit of design systems is their ability to
                replicate designs quickly by utilizing premade UI components and
                elements.

                <ul class="list">
                  <li>
                    Design System will provide a consistent, scalable, and
                    reusable code base.
                  </li>
                  <li>
                    Design systems and frameworks help companies by making it
                    easier to share knowledge across the team.
                  </li>
                  <li>
                    Design System will provide a common system for all our
                    platforms to use
                  </li>
                  <li>
                    With a Design System in place, you can concentrate on what's
                    important
                  </li>
                  <li>
                    It allows you to easily apply the same design elements,
                    layouts, and effects across the whole product.
                  </li>
                  <li>
                    You’ll get the look and feel of your product down pat in no
                    time without having to spend too much time or budget on
                    building something that can be quickly replicated down the
                    line.
                  </li>
                </ul>
              </div>
              <div class="desc">
                The Design Systems and frameworks help companies scale well by
                making it easier to share knowledge, reuse designs, and enforce
                a consistent approach across the team. <br />In short, a Design
                System helps you achieve consistency in your designs, improve
                usability and accessibility, facilitate faster development, and
                make future updates easier to integrate
              </div>
              <img alt="Frameworks" src="./../assets/post2/223.jpg" />
              <h2 class="title-small">
                What are the Six Components of a Design System?
              </h2>
              <div class="desc">
                <ul class="list bold num-list">
                  <li>
                    Design language
                    <div class="regular">
                      A design language is a set of carefully considered
                      semantics and visuals that are brought together to create
                      unity, visual consistency, and clarity in the product. It
                      is a set of principles, guidelines, and standards for
                      creating digital products. By using it, you can create
                      systems that are consistent and easy to use for everyone.
                    </div>
                  </li>
                  <li>
                    Design kit

                    <div class="regular">
                      The use of System Kits saves time. In a matter of minutes,
                      you may build a web or mobile design. Design System Kits
                      are equipped with a UI library that is ready to be
                      utilized with actual, functioning design systems. Some of
                      them feature UI Kit samples.
                    </div>
                  </li>
                  <li>
                    Component library

                    <div class="regular">
                      All the styles and elements used in a website, piece of
                      software, or application, such as buttons, input fields, a
                      UI kit, and more, are contained in a single file or folder
                      known as a component library. It is scalable, versatile,
                      and responsive to the web.
                    </div>
                  </li>
                  <li>
                    Developer Sandbox
                    <div class="regular">
                      The developer sandbox is a tool that offers a location for
                      building components in isolation, including writing
                      structural and visual tests, as part of the design
                      system's standardization.
                    </div>
                  </li>
                  <li>
                    Documentation
                    <div class="regular">
                      The list of components in a design system makes up the
                      majority of the documentation; at its core, this is a
                      pattern library with the necessary code to create and
                      construct from the components. To build interfaces,
                      screens, and workflows, the design system's component
                      parts can be combined.
                    </div>
                  </li>
                  <li>
                    Governance model

                    <div class="regular">
                      The authority that oversees compliance, risk, legal,
                      financial, and audit matters should be specified in
                      governance models. The model should specify how the board
                      will manage risks in all geographical areas and business
                      units. The organizational structure and reporting lines
                      should be made transparent to stakeholders and employees.
                    </div>
                  </li>
                </ul>
              </div>
              <h2 class="title-small">How UVIO helps you?</h2>
              <div class="desc">
                UVIO creates frameworks that build apps and webpages without
                exponentially increasing your costs. UVIO creates a visual
                consistency across all your today and tomorrow products that
                affect customers’ experience in a positive way. The Design
                System we build bridges the gap between developers and designers
                and works move on faster. UVIO’s unique design system improves
                communication across all internal and external stakeholders.
              </div>
              <div class="desc">
                Book a call if you need assistance with Design System creation,
                as well as full-cycle web and mobile application development.
              </div>
              <div class="desc">
                Next: Development Sandboxes: An Agile Core Practice
              </div>
            </div>

            <!-- post 4 -->
            <div class="text" v-if="activeBlog.url == 'development-sandboxes'">
              <img
                src="./../assets/post4/225.jpg"
                alt="Development Sandboxes"
              />
              <div class="desc">
                In the context of software development, including Web
                development, automation, and revision control, a sandbox is a
                testing environment that isolates untested code modifications
                and outright experimentation from the production environment or
                repository.
              </div>
              <div class="desc">
                Regardless of the author's intentions, sandboxing guards "live"
                servers and their data, vetted source code distributions, and
                other collections of code, data, and/or content, whether
                proprietary or public, from alterations that might harm a
                system's mission-critical functionality or that, might be
                challenging to undo.
              </div>
              <div class="desc">
                Sandboxes replicate at the very least the essential
                functionality required for testing programs or other developing
                code accurately (e.g. usage of the same environment variables
                as, or access to an identical database to that used by, the
                stable prior implementation intended to be modified; there are
                many other possibilities, as the specific functionality needs
                vary widely with the nature of the code and the applications for
                which it is intended).
              </div>

              <h2 class="title-small">Why is it called a sandbox?</h2>
              <div class="desc">
                The term sandbox comes from the idea of a child's sandbox, in
                which the sand and toys are kept inside a small container or
                walled area so children can play safely. With the sandboxing
                approach, every software application is given a "sandbox," which
                is a controlled, restricted environment to run and execute code.
              </div>

              <h2 class="title-small">
                What kinds of sandboxes are we knowing?
              </h2>
              <div class="desc">
                There are actually four different sorts of sandboxes, and each
                one has varying degrees of features and functions. When these
                four various sorts of sandboxes are recognized and exploited in
                accordance with their advantages, businesses have the freedom to
                easily develop and modify them for their proper use.
              </div>

              <h2 class="title-small">
                1. The smallest and most basic Sandbox is a Developer Sandbox
              </h2>
              <div class="desc">
                The smallest and most basic Sandbox is a Developer Sandbox.
                Utilize this sandbox in a private setting for testing and
                development. There is a copy of the organization's metadata
                available when using a Developer Pro Sandbox.
              </div>
              <h2 class="title-small">2. Developer Pro Sandbox</h2>
              <div class="desc">
                Larger data sets can be handled by a Developer Pro Sandbox than
                by a Regular Developer Box. Use this one for further development
                and quality control duties, as well as for user training or
                integration testing.
              </div>
              <h2 class="title-small">3. Partial Copy Sandbox</h2>
              <div class="desc">
                A partial Copy Sandbox is mostly used as a testing set. Some
                records from your production environment will be imported into
                this Sandbox, but not all. The best applications for this are in
                user acceptability, integration testing, and quality assurance.
              </div>
              <h2 class="title-small">4. Full Sandbox</h2>
              <div class="desc">
                A Full Sandbox provides all of your options. Full sandboxes are
                exact copies of your production organization, replete with all
                data, metadata, and object records and attachments. Performance
                testing, load testing, and staging are only supported by Full
                sandboxes. The biggest Sandbox, this one is advised for
                full-scale testing and QA rather than development. It is crucial
                to utilize a sandbox template when utilizing a Full Sandbox so
                that it only contains the records you need for testing and other
                purposes.
              </div>
              <img alt="Full sandbox" src="./../assets/post4/226.jpg" />

              <h2 class="title-small">
                Why sandboxing environment is important?
              </h2>
              <div class="desc">
                Virtual servers are used in sandboxing technologies to test
                software in a secure setting. Without having to worry about
                compatibility concerns brought on by background apps, developers
                can test individual functionalities.
              </div>
              <div class="desc">
                Environments for sandboxing are simple to restructure for
                recurrent use. They are therefore the ideal choice for testing
                IT solutions. Without compromising their own systems, a business
                can examine questionable software, harmful code, and other
                threats.
              </div>

              <h2 class="title-small">
                What Are the Main Benefits of a Development Sandbox?
              </h2>

              <div class="desc">
                <ul class="list">
                  <li>
                    <b>Create and deploy your environments with ease.</b>
                    Sandboxes make it simple to deploy environments at a large
                    scale. Specific versions can be tested, new lines of code
                    can be introduced and tested in comparison to your control,
                    and more.
                  </li>
                  <li>
                    <b>Boost departmental cooperation across the board.</b>
                    Collaboration is a crucial component of any enterprise.
                    Sandboxes are excellent for gathering user input from the
                    many departments in your business since anyone with the
                    necessary permissions can access them.
                  </li>
                  <li>
                    <b
                      >Access to nested virtualization support and advanced
                      networking.</b
                    >
                    Working with a respected sandbox technology vendor gives you
                    access to sophisticated networking features and complicated
                    topologies without the need for re-architecting.
                  </li>
                  <li>
                    <b>Your company will save a lot of money.</b> Owning,
                    operating, and staffing your own development labs costs
                    money. With cloud-based sandboxing, you can only pay for the
                    services you actually utilize.
                  </li>
                </ul>
              </div>
              <h2 class="title-small">The role of the designer tomorrow</h2>

              <div class="desc">
                Anyone involved in design or technology will have observed how
                quickly we transitioned from terminology like "World Wide Web"
                to "Internet of Things."
              </div>
              <div class="desc">
                In a short period of time, we've transitioned from creating and
                designing web pages—points on the information superhighway's map
                that users access through browsers—to future user interfaces
                (UIs), which are networks of connected applications that deliver
                content and experiences across a variety of form factors,
                resolutions, and interaction patterns.
              </div>
              <div class="desc">
                Previously, the goal of the digital designers' work was to
                arrange a complex array of boxes, graphics, text, and buttons on
                a reasonably reliable browser screen (and eventually a mobile
                app screen).
              </div>
              <div class="desc">
                This was the scope of the experience that was offered to the
                public. However, designers, today must take into account the
                fact that goods exist as distinct entities within intricate
                networks of components that interact, exchange information with,
                and occasionally even vanish behind other elements.
              </div>
              <div class="desc">
                Additionally, this goes beyond content. Designers today must
                take into account how each product or service supplied to users
                fits into and interacts with other parts of various systems and
                platforms.
              </div>
              <div class="desc">
                A single product will be perceived and used differently in
                various circumstances as a result of the proliferation of
                multiple, connected technologies.
              </div>
              <div class="desc">
                At least in the way it has been viewed thus far, the mobile app
                is no longer the unit of design.
              </div>
              <div class="desc">
                For customers to access the content and services that products
                have to offer, the standalone application is increasingly
                serving as a secondary or even tertiary location. How can
                designers adjust their methods to best satisfy the demands of
                people?
              </div>
              <div class="desc">
                The new approach is to design less for pixels and more for bits
                of content and interactions. How is the new object of a
                designer's work defined as moments of experiences that flow
                non-linearly from device to device and the component is the new
                unit of design? It will become increasingly important to develop
                experiences rather than UIs.
              </div>
              <div class="desc">
                In order to develop goods and services that can adapt to survive
                in many ecosystems, designers must change, pivot, and refocus
                their attention. Future user interfaces (UIs) will be used in a
                variety of unanticipated circumstances and devices, and
                designers will have a lot to think about as ambient intelligence
                develops.
              </div>
              <h2 class="title-small">
                How does the development sandbox play an important role in the
                future designer’s role?
              </h2>
              <div class="desc">
                When ambient intelligence develops, you need to experiment with
                more opportunities to satisfy User Experience. Sandboxes give
                you an environment to test whatever is needed. Experimentation
                can result in discoveries, fresh interpretations, and accidental
                inventions. In the past, experimentation has been an indication
                of a designer since it shows inventiveness, intelligent
                research, and a synthesis of concepts and techniques. The design
                process is now mostly streamlined into a linear procedure that
                moves from research to criteria to idea creation to
                specification. And while we can counter that designers
                experiment with different methods and take into account
                alternative viewpoints, the audacious notion of deliberate play
                through experimentation has significantly weakened overall.
              </div>
              <h2 class="title-small">Why does this matter?</h2>
              <div class="desc">
                There have been challenges for Small and Medium Size enterprises
                to find and contact a professional team that matches their best
                interest for a winning product’s rebrand or redesign. We at UVIO
                are forward thinkers and we are leading the industry with a new
                concept of future designers. That’s why Fortune 500 names are
                our partners and clients.
              </div>
            </div>

            <!-- post 5 -->
            <div class="text" v-if="activeBlog.url == 'data-driven-design'">
              <img src="./../assets/post5/227.jpg" alt="Data Driven Design" />
              <h2 class="title-small">What is DDD?</h2>
              <div class="desc">
                Health and social care industries are booming with digital
                platforms and applications. They include artificial intelligence
                wearable technologies, mobile applications, websites, and
                various clinical data storing and sharing systems that we know
                as platforms.
              </div>
              <div class="desc">
                If you have worked in Healthcare UX/UI design you should have
                faced so many challenges and I ask you a question to help you
                with learning a new concept that helps you very much.
              </div>
              <div class="desc">
                Do you ever get frustrated by the amount of time you spend
                creating and updating designs while your engineering team is
                crunching on code? That’s where data-driven design comes in.
                Let's break down the term: data-driven design refers to the
                practice of using user data to inform design decisions before
                code is written. Data-driven design is the process of defining,
                evaluating, and refining an interface by using the actual data
                created by a user before that interface was in place. In other
                words, it's about creating experiences based on facts rather
                than assumptions. There are plenty of reasons why this can be
                valuable. In fact, making informed decisions based on data is
                always better than making uninformed decisions! The result is
                faster iteration cycles and a deeper understanding of user
                behavior, which leads to better products for your users.
              </div>
              <div class="desc">
                Data-driven design (DDD) is a revolutionary approach to
                problem-solving and innovation in the healthcare technology and
                digital health industry. Data-Driven Design (DDD) is the
                practice of designing digital products with a strong focus on
                metrics and analytics. Data-Driven Design allows you to test
                those ideas before you invest time and money in them, making the
                results more reliable, accurate, and helpful.
              </div>
              <div class="desc">
                It aims to build healthcare applications that are flexible,
                maintainable, and scalable. It requires user research that
                provides deep insights into the users’ needs, pain points, and
                use cases.
              </div>
              <div class="desc">
                At its most basic, data-driven design is about bringing your
                users to the center of the design process. When you use DDD, you
                focus on your users and their needs before making any decisions
                about how to structure your product or how best to measure
                success. In addition to using data to inform design decisions,
                DDD also incorporates tools that are used by data scientists
                like machine learning algorithms and natural language
                generation.
              </div>

              <h2 class="title-small">Correlation between UX/UI and DDD</h2>
              <div class="desc">
                Data-driven design, also known as data-informed design or
                evidence-based design, is a process for designing products that
                incorporates a scientific approach. This type of design involves
                the user experience (UX) and user interface (UI) designers
                creating prototypes and then applying data to determine whether
                or not it needs to be changed. The goal is to create a product
                that matches customer needs by using quantitative and
                qualitative research data on its users.
              </div>
              <div class="desc">
                In other words, Data-driven design uses data to influence and
                inform all aspects of the user experience (UX) design process.
                It is the practice of using metrics from implementation to drive
                your design decisions, rather than relying on opinion or gut
                instincts.
              </div>
              <div class="desc">
                In the marketing world, DDD has been used for years to help
                companies personalize product experiences for their customers.
              </div>

              <img alt="Data driven design" src="./../assets/post5/228.jpg" />
              <h2 class="title-small">The Impact of Data on UX Design</h2>
              <div class="desc">
                Data collection must be done very carefully. In data-driven
                design, two major categories of data are taken into
                consideration and aid in the design process:
              </div>
              <div class="desc">
                <ul class="list">
                  <li>
                    Quantitative data- numerical information that demonstrates
                    who, what, when, and where. Scale is revealed through
                    quantitative data. Quantitative data can't explain why,
                    though. Google Analytics provides a wealth of quantifiable
                    information about how users interact with a website or
                    application.
                  </li>
                  <li>
                    Qualitative data: Information that explains why and how. Why
                    do certain visitors behave one way while another group does
                    something else? Why do some articles draw more readers to
                    your website than others? Qualitative data provides context
                    and enables us to comprehend not just what occurred, but
                    also why and how it did. Personas or trip maps are
                    frequently used to express qualitative data.
                  </li>
                </ul>
              </div>
              <div class="desc">
                Empirical data are always the best type of qualitative and
                quantitative information.
              </div>
              <h2 class="title-small">
                The Value of DDD Thinking in User Experience Design
              </h2>
              <div class="desc">
                The most comprehensive source of data for UX designers may be
                design analytics (DA). This information ranges from Web
                analytics to the findings of A/B tests.
              </div>
              <div class="desc">
                Design should be viewed as an investment rather than a cost.
                Designing a product that is aesthetically pleasant is
                insufficient. It must also function. Usability, which is another
                word for usability, is quite important. Data-driven design is
                important because it helps you
              </div>
              <div class="desc">
                <ul class="list num-list">
                  <li>Recognize your users' requirements</li>
                  <li>Go beyond recommended practices</li>
                  <li>Create designs that work</li>
                  <li>Utilize data to spur creativity</li>
                </ul>
              </div>
              <img alt="Designs that work" src="./../assets/post5/229.jpg" />
              <h2 class="title-small">Recognizing Your Users</h2>
              <div class="desc">
                Users are not your designers. With the help of UX research,
                particularly generative user research and usability testing,
                designers may close the gap. Usability testing is frequently
                viewed in the IT services and product industries as redundant
                when a talented UX designer is working on an application or
                product. But all too frequently, even hiring a top-notch
                designer is not enough to ensure a product's success. What users
                desire and need cannot be foreseen by designers. Users are not
                your designers. They have various backgrounds and viewpoints.
                They both use technology in different ways and have various
                expectations. Fortunately, UX research may help designers close
                the gap, particularly generative user research and usability
                testing.
              </div>
              <h2 class="title-small">Go beyond recommended practices</h2>
              <div class="desc">
                UX designers can get rid of their presumptions and reliance on
                best practices with the use of data-driven design. Each sector,
                company vertical, and industry is distinct. You cannot generate
                empathy for your target audience by strictly adhering to design
                principles or by utilizing the newest digital design trends. To
                enhance the user experience of a product, designers must gather
                insights that are relevant to their target market. Findings from
                user research, usability test results, website analytics, and
                survey data are just a few examples of the kind of information
                that can assist designers in making choices that will improve
                the user experience.
              </div>
              <h2 class="title-small">Create designs that work</h2>
              <div class="desc">
                Many businesses find it challenging to strike a balance between
                users' needs and corporate goals. A project's success may suffer
                severely if data analytics are not taken into account. Effective
                data use can immediately result in better business outcomes.
              </div>
              <div class="desc">
                UX design can benefit from UX analytics. High departure and
                bounce rates, for instance, can show that a page doesn't include
                the data a site visitor was looking for. Or, if the information
                is present, it could be difficult to locate or comprehend
                whether it is. It's also crucial to pay attention to UX data as
                you make design adjustments. Pay attention to how a page
                performs after a redesign if it was functioning well previously.
                A rise in positive indications indicates that the redesign is
                succeeding, and vice versa.
              </div>
              <h2 class="title-small">Utilize data to spur creativity</h2>
              <div class="desc">
                Many claims that the data-driven approach to design prevents
                innovation, although these objectives don't necessarily have to
                conflict. It's true that trying to increase conversion rates by
                even modest percentages might keep UX designers from coming up
                with new ideas, but the issue isn't with using data; it's with
                how you're using it. Designers should be able to suggest bold,
                radical modifications. Designers should support their design
                hypotheses with data if they want their clients or other
                stakeholders to approve of the implementation of their designs.
              </div>
              <div class="desc">
                For a better understanding of your up-sell opportunities,
                interview your present consumers about their buying habits.
              </div>
              <h2 class="title-small">How can we help you in UVIO?</h2>
              <div class="desc">
                We at UVIO use human-centered UX design principles to create
                applications that make healthcare more convenient, efficient,
                and easier to understand. We believe that data is everything in
                the world of design and development. That is why we want to make
                sure you have everything you need to create an amazing digital
                product.
              </div>
            </div>

            <!-- post 6 -->
            <div
              class="text"
              v-if="activeBlog.url == 'ux-design-in-healthcare'"
            >
              <img alt="UX in healthcare" src="./../assets/post6/1230.jpg" />
              <h2 class="title-small">Healthcare UX: what is it?</h2>
              <div class="desc">
                The design of the user experience for any healthcare service or
                product, such as electronic health records, illness management
                applications, or apps for booking doctor appointments, is
                referred to as healthcare UX.
              </div>
              <div class="desc">
                The necessity to provide a good user experience has increased
                along with the growth of digital transformation in healthcare.
                This holds true for administrative, diagnostic, and therapeutic
                tools used by medical experts in addition to telemedicine.
              </div>
              <div class="desc">
                Patient decision-making can be improved by good healthcare UX
                design, which also helps to reduce stress and uncertainty.
                Lowering errors can improve the effectiveness of both diagnosis
                and therapy. Additionally, it helps lessen the cognitive burden,
                a characteristic that usually contributes to practitioner
                burnout.
              </div>
              <div class="desc">
                In general, consumers may now more readily get medical care and
                guidance from healthcare specialists because of the integration
                of UX design into the healthcare system. One of the most
                effective ways UX can change people's lives is through this,
                which can have a huge positive impact on patients' health.
              </div>
              <div class="desc">
                A healthcare app that serves as a good UX design illustration
                for streamlining lengthy forms is Calm. The software begins with
                a properly thought-out form on a sequence of screens, providing
                a setup that doesn't demand much mental work.
              </div>
              <div class="desc">
                It creates an excellent initial impression by establishing a
                straightforward and laid-back rapport with the user. This type
                of design thinking typically results in products that people can
                use with little effort and has a reduced abandonment rate. It
                tells potential customers that using this system would be
                simple, comfortable, and intuitive.
              </div>
              <img alt="healthcare UX" src="./../assets/post6/1231.jpg" />
              <h2 class="title-small">
                UX design application in the healthcare industry
              </h2>
              <div class="desc">
                <b>Telemedicine</b> – As telemedicine spreads, particularly in
                distant and historically underprivileged areas, there is an
                increased need to create telehealth apps that are inclusive,
                user-friendly, and closely resemble in-person encounters. By
                considering various user characteristics like age, technical
                proficiency, and mental state and then coming up with innovative
                ways to capture and recreate the in-person experience between
                doctors and patients in a virtual environment, UX designers,
                researchers, and analysts can have the most significant impact
                in this area.
              </div>
              <div class="desc">
                <b>Accessibility</b> — When it comes to healthcare technology,
                accessibility is a crucial component of the user experience. It
                frequently falls on the UX designer to advocate for these
                various types of users, making sure that the products and
                platforms are designed to be intuitive, and user-friendly, and
                take into account the different accessibility issues a user
                might face. This is especially true for users of telemedicine
                services, health and fitness apps, and appointment registration
                services, who frequently have a disability, low-tech literacy,
                or poor internet connections.
              </div>
              <div class="desc">
                <b>Wearables</b> – Fitness trackers and other wearables continue
                to gain popularity, and by 2023, it's predicted that 1.1 billion
                of them will be in use worldwide. Device manufacturers must
                figure out new ways to prioritize information without
                overwhelming the user as these devices create vast amounts of
                user data, from steps taken to crucial statistics. UX designers
                may make a difference in this situation by first understanding
                the demands and behaviors of the users, then by employing visual
                communication design to facilitate users' rapid and simple
                access to and comprehension of the data presented to them.
              </div>
              <div class="desc">
                <b>Virtual reality simulations</b> — According to the Harvard
                Business Review, VR technology has developed to the point that
                it can provide "a highly granular image of what surgeons are
                doing well and [identifying] areas for development." VR has
                recently become a popular teaching tool for surgeons. In
                comparison to conventional training techniques, the report
                indicated that VR training on a VR platform "increased
                participants' overall surgical performance by 230%." For these
                instruments, user experience (UX) designers must provide a
                comprehensive user interface that is accurate and realistic
                while also catering to the demands of surgeons.
              </div>
              <div class="desc">
                <b>Electronic health records</b> – As medical records move
                online, digitizing data by itself does not make it simpler or
                more effective to get it. In order to make sure that any
                electronic health record software developed will actually be
                useful to the end user, UX designers should have knowledge and
                understanding of how medical staff typically interact with a
                system. They should take into account things like what the user
                prioritizes, the sequence of actions performed when using a
                database, and what fields are required versus what can be left
                blank.
              </div>
              <h2 class="title-small">Healthcare UX Challenges</h2>
              <div class="desc">
                There have been some significant changes in the healthcare
                industry in recent years. In addition to having doctors take
                care of our health, we also have access to a wide range of
                digital devices that can improve our performance and quality of
                life. As a result, the medical industries began to use digital
                gadgets more frequently as well. Additionally, the patient as a
                customer is typically at the center of most healthcare products.
                Treatments are no longer dull necessities; they are becoming
                services.
              </div>
              <div class="desc">
                The healthcare system now uses modern technologies to give
                patients better, more relaxing, and safer care. However, the
                technology must be user-friendly for this to operate. The
                long-term viability of these apps and gadgets depends on their
                use. Healthcare professionals should feel comfortable using
                these technologies on a daily basis. According to our
                experience, the design approach for a healthcare product should
                pay particular attention to what doctors struggle with the most,
                as well as the treatments and equipment that patients require.
              </div>
              <img
                alt="Importance of healthcare UX"
                src="./../assets/post6/1233.jpg"
              />
              <h2 class="title-small">
                The Importance of Healthcare UX Design
              </h2>
              <div class="desc">
                Healthcare organizations are in danger of falling behind as the
                sector shifts to a patient-centric focus if they don't
                personalize the user experience. That's why healthcare UX design
                is so important. It's partially about meeting individuals where
                they are in terms of their health and offering them appropriate
                information. It is also important to understand the user. If I'm
                using your service or program, for example, what do I want to do
                on my phone versus what I want to do on my watch versus what I
                want to do on my desktop? It is important to understand how the
                consumer consumes the content when it comes to personalization.
                The user should be able to select whether or not to share
                information between devices or programs, or even between persons
                in their care network.
              </div>
              <h2 class="title-small">Healthcare platform: what is it?</h2>
              <div class="desc">
                Web Platform refers to the version of the cloud-based
                collaborative software solution and the software application
                that goes along with it, as updated from time to time by the
                company and made available to the user as part of the services
                supplied by the company.
              </div>
              <div class="desc">
                Healthcare is all about providing organized services to
                individuals or communities. With a fast shift toward
                digitalization and digital transformation, healthcare services
                are now available in a digital environment. So, in order to
                offer digital data—transmitted, saved, and retrieved
                electronically—for clinical, educational, and administrative
                objectives, a "health platform"—hardware and an operating
                system—must be designed and used.
              </div>
            </div>

            <!-- post 7 -->
            <div
              class="text"
              v-if="activeBlog.url == 'modern-ux-design-research'"
            >
              <img
                alt="Modern UX design research"
                src="./../assets/post7/1234.jpg"
              />
              <h2 class="title-small">
                What is the meaning of User Testing?
              </h2>
              <div class="desc">
                User testing is a procedure used to evaluate the user experience
                and features of a website, program, mobile application, or
                service. In particular, actual users who carry out the testing
                process under actual testing conditions are involved in this
                user testing evaluation.
              </div>
              <div class="desc">
                Usability testing, often known as user testing, is the phase of
                the design process that allows you to test your product or
                service on actual customers and develop human-centered designs.
                You can actually investigate and examine how your target market
                interacts with your goods through this procedure.
              </div>
              <div class="desc">
                What three forms of user testing are there?
              </div>
              <div class="desc">
                <ul class="list">
                  <li>
                    Moderated vs. unmoderated testing is one of the three
                    general forms of usability testing
                  </li>
                  <li>Online versus in-person.</li>
                  <li>Comparative versus exploratory.</li>
                </ul>
              </div>
              <h2 class="title-small">
                Advantages of User Testing for Businesses
              </h2>
              <div class="desc">
                - Provides a thorough understanding of the client experience
              </div>
              <div class="desc">
                - Gains knowledge and provides real-time feedback on customer
                opinions on the product.
              </div>
              <div class="desc">
                - Indicates or states whether the product needs any
                improvements.
              </div>
              <div class="desc">
                - Aids in providing first-hand information about the product by
                having actual people try it
              </div>
              <div class="desc">
                - Serves as the ideal stage validation for any design flaws. And
                this is the main point, Early bug discovery guarantees quicker
                ways to improve the product and often saves time and money.
                Additionally, in today's cutthroat marketplace, producing
                mediocre goods won't guarantee business success. Therefore, only
                items with exceptional user experience (UX) will win the
                customer's loyalty, are certain to please customers, and provide
                a positive customer experience (CX).
              </div>
              <h2 class="title-small">What is qualitative user research?</h2>
              <div class="desc">
                An effective way to learn more about the individuals using your
                product is through qualitative user research. It focuses on how
                each person sees and experiences the world. You must take into
                account your design objectives and choose the method that best
                serves your objective if you want to get the most out of
                qualitative research.
              </div>
              <div class="desc">
                There are research techniques included in qualitative user
                research, which enable you to gather qualitative data by closely
                observing and analyzing individuals. Understanding human
                motivations, thoughts, and attitudes are possible thanks to
                qualitative data.
              </div>
              <h2 class="title-small">What is quantitative user research?</h2>
              <div class="desc">
                In contrast, the type of information gathered by quantitative
                approaches is predetermined and may include task duration,
                success, whether a user has clicked on a specific UI element, or
                whether they choose a particular response from a list of
                options. Since the instrument used to collect the data (such as
                a survey tool or analytics tool) collects such vast quantities
                of data that are automatically coded numerically, the insights
                in quantitative approaches are often derived from mathematical
                analysis.
              </div>
              <div class="desc">
                A minimum of 20 people are needed for this procedure, and it
                frequently calls for considerably more. Quantitative behavioral
                user testing Researchers that study user experiences seek trends
                in the data they have gathered and always like to have more
                users because more participants who are familiar with the
                product tend to provide more accurate results.
              </div>
              <img alt="Quantitive approach" src="./../assets/post7/1235.jpg" />
              <h2 class="title-small">Methods in UX Research</h2>
              <div class="desc">
                <ul class="list">
                  <li>Interviews.</li>
                  <li>Ethnographic field studies.</li>
                  <li>Diary studies. Observations or “Shop-Alongs”</li>
                  <li>Focus groups.</li>
                  <li>Moderated usability testing.</li>
                  <li>Card sorting.</li>
                  <li>In-Home Videos.</li>
                </ul>
              </div>
              <h2 class="title-small">
                What are the Fundamental Components and Steps in User Testing?
              </h2>
              <div class="desc">
                Although there are many crucial principles in UX design, we've
                condensed them into five key ideas in order to make things a
                little clearer: empathy, strategy, usability, inclusivity, and
                validation.
              </div>
              <div class="desc">
                <ul class="list">
                  <li>Empathy</li>
                </ul>
              </div>
              <div class="desc">
                Users' requirements are prioritized in product creation by
                quality UX design. UX designers need to practice empathic design
                in order to comprehend the user and the challenges they
                confront. Empathy allows designers to put themselves in the
                user's position and learn about their daily activities, problems
                that need to be solved, expectations they have while using
                particular products, and what they consider to be a positive
                user experience.
              </div>
              <div class="desc">
                <ul class="list">
                  <li>Strategy</li>
                </ul>
              </div>
              <div class="desc">
                It can be tough to come up with efficient answers to challenging
                challenges. So having a clear plan and set of objectives before
                beginning any project is essential to UX design. Many designers
                will create a UX strategy that details various UX goals,
                preferred research techniques, design, and testing frameworks,
                and what success looks like for the user and the company. A UX
                strategy should be followed but is not set in stone and can be
                changed as designers progress through the design phase.
              </div>
              <div class="desc">
                <ul class="list">
                  <li>Usability</li>
                </ul>
              </div>
              <div class="desc">
                Consumers frequently favor things that need less cognitive
                effort to use in a world of quickly changing and perhaps
                perplexing technology. The last thing a user wants is to think
                that placing a phone order for delivery meals is like putting
                together a 1000-piece puzzle. Making products and services that
                are easy and intuitive for users of all ages and abilities is a
                key aspect of user experience design. When creating the best and
                easiest usability, designers take into account factors like
                behavioral psychology, accessibility, affordances, and mental
                models.
              </div>
              <div class="desc">
                <ul class="list">
                  <li>Inclusivity</li>
                </ul>
              </div>
              <div class="desc">
                The term "design for all," which means that our design efforts
                should take into account the demands of people of various races,
                ages, genders, backgrounds, socioeconomic levels, and more, is
                frequently used in the user experience (UX) business. UX
                designers have the power to address complicated issues that have
                an impact on a variety of people and to change the world via
                their solutions.
              </div>
              <h2 class="title-small">Why is good UX design crucial?</h2>
              <div class="desc">
                Simply defined, UX is significant because it aims to meet user
                needs. It strives to deliver satisfying experiences that
                maintain customers' loyalty to the good or service. Furthermore,
                defining client journeys on your website that are most
                supportive of company success is made possible by a meaningful
                user experience.
              </div>
              <div class="desc">
                Engaging interaction subtly leads the user to the intended
                action through good UX design. User experience is one way that
                great design adds value, and it also helps to point people in
                the direction of a product's objective.
              </div>
              <div class="desc">
                UX is the intermediary between what you have to give and the
                customer, despite the fact that to some it may just seem like
                another layer of design. It can determine whether a customer
                visits your website again or navigates away to less complicated,
                better pastures.
              </div>
              <h2 class="title-small">
                How UVIO will run you through this process?
              </h2>
              <div class="desc">
                At UVIO we know the ropes. We take the time to do our own market
                research, conduct stakeholder interviews, and craft competitor
                analyses first. We work with real-time data from real users.
                Then, in close cooperation with the client, we leverage the data
                from our research to inform a clear product-development strategy
                that delivers results end-users appreciate.
              </div>
            </div>

            <!-- post 8 -->
            <div
              class="text"
              v-if="activeBlog.url == 'the-importance-of-ux-ui-design'"
            >
              <img alt="UX importance" src="./../assets/post8/1236.jpg" />
              <div class="desc">
                Good user experience (UX) and user interface (UI) design will
                define your product's conversion and success. User experience is
                your customer's feeling when he interacts with your product. A
                meaningful, positive user experience will keep the users loyal
                to your business and give more insight into their journeys.
              </div>
              <div class="desc">
                At Uvio, we highly value every part of the software development
                process and give special attention to UX UI design. It is the
                part that connects the goal of the product and how we deliver
                this goal to the user. Here is why premium quality UI UX design
                is essential for your business.
              </div>
              <h2 class="title-small">1. Increase conversion rates</h2>
              <div class="desc">
                The process here is simple and solid. Whether you want to be
                contacted by your prospects, sell a product, get subscribers or
                encourage downloads, you will get there through high-quality UX
                UI design. The user experience needs to be qualitative to lower
                bounce rates and get the clients to take the action you need on
                your page.
              </div>
              <h2 class="title-small">
                2. Good UX UI design establishes trust, credibility, and loyalty
              </h2>
              <div class="desc">
                Good UI design increases user involvement because it defines how
                users interact with your products on the way to achieving their
                goals. Seamless UI UX creates a more vital link between your
                customers and your product. Optimised usability of a website has
                a strong effect on customer satisfaction.
              </div>
              <h2 class="title-small">3. UX design maximises ROI</h2>
              <div class="desc">
                ROI (Return on Investment) is a metric that shows how well your
                financial investment into business has performed — what it has
                returned to you. <br />A solid investment into UX UI can turn
                into ROI. Forrester Research claims that 50% of potential sales
                don't go through because the user needs help finding the correct
                information. Out of 10 users looking for a way to check out,
                only one will ask the support team directly, and the rest will
                go onto more user-friendly solutions. Good UX design means the
                user can get to the destination quicker, and a good experience
                is worth 1000 feedback.
              </div>
              <h2 class="title-small">
                4. Right UX improves customer acquisition
              </h2>
              <div class="desc">
                You need to convince your customer that your service is worth
                their money. Client acquisition is a key metric in business, and
                one of the ways to attract new loyal customers is through user
                experience. <br />The more appealing your solution, the more
                competitive advantage you have in front of your competitors.
                With specific features and a seamless experience, you will
                acquire new clients faster and for less money.
              </div>
              <h2 class="title-small">5. UX enhances SEO</h2>
              <div class="desc">
                Combining UX design and good SEO is that optimised SEO with UX
                works miracles. Let us break it down why. Imagine you want your
                business to rank as high as possible for "marketing automation".
                When the customer discovers your marketing automation solution
                is exceptional, it will be a win-win for you. In this case,
                "marketing automation" is your SEO term. <br /><br />However,
                when the customer arrives on your page looking for "marketing
                automation" and he doesn't see directly what you are offering,
                chances are he will go with a different service provider. A
                search engine will see that even though you are trying to rank
                for "marketing automation", the search term is not converting,
                and the potential customer left your page after trying to find
                "marketing automation". The search engine has no reason to rank
                you high for this particular term. <br /><br />On the other
                hand, if the search engine sees directly that the customer found
                what he was looking for on the website, it has every reason to
                rank you higher for your preferred terms.
              </div>
              <h2 class="title-small">6. UX improves customer retention</h2>
              <div class="desc">
                Customer retention means making the best of the existing client
                base, and you must often put in as much work as you would with
                acquiring customers. Client retention methods are how a business
                maintains customer connections and encourages permanent income.
              </div>
              <h2 class="title-small">Conclusion</h2>
              <div class="desc">
                More customers will want to use a business application that is
                easy and appealing, which you can reach with great UI/UX design.
                Reach out to us, and we will help you elaborate on a new design
                for a future solution or work on improving the UX UI in the
                existing one.
              </div>
            </div>

            <!-- post 9 -->
            <div
              class="text"
              v-if="activeBlog.url == 'uvio-is-one-of-clutchs-game-changers'"
            >
              <img alt="Uvio" src="./../assets/post9/1237.jpg" />
              <div class="desc">Finding the perfect IT partner for your business is truly daunting! There are a lot of factors to consider and there are also a lot of options available in the market today. Thanks to the popularity of the IT industry, more and more companies are seeking reliable partners to help navigate their way into these new technologies and worlds of innovations.
              </div>
              <div class="desc">For starters, you should consider an IT agency with the experience to back up their claims! You also need to look into the services they offer and if they fit your current news. These are all that our company has to offer and more! At UVIO, you are getting more than5 years of experience in the IT industry and a variety of services that utilizes the latest technologies and solutions from the market.
              </div>
              <div class="desc">Founded in 2016, our team has been of the digital agencies that offer comprehensive digital services and amazing digital experiences. Our team will envision your solutions, help implement innovative designs, and most importantly, will help you in bringing the best outcome for your business.
              </div>
              <a href="https://clutch.co/profile/uvio#reviews" target="__blank">
                <img alt="Uvio" src="./../assets/post9/1238.jpg" />
              </a>
              <div class="desc">Our dedication to the industry has enabled us to win a number of awards and accolades in the market. Today, we are proud to announce that we are adding one under our belt as we introduce our latest Clutch recognition! We’ve been recently named as one of the game changers in California’s IT industry and we are delighted to share this amazing news with you, our partners.
              </div>
              <div class="desc">Of course, we couldn’t have achieved this without your help! Here are some of our team’s picks for the most amazing reviews and highest <a href="https://clutch.co/it-services/california?utm_source=directory&utm_medium=blog&utm_campaign=surgical" target="__blank">B2B ratings</a> we received from all of you.
              </div>
              <div class="desc"><i>“I was amazed with the awesome design they came up with and the custom solutions they were able to create in order to make my business function. For them, it's not just about making the site look pretty. They want it to function seamlessly as well.”  Greg Webb, Owner of Purple Rocket Podcasts
              </i></div>
              <div class="desc"><i>“They did a wonderful job. I would use them again without question. The way they all work together. They are like a family that has operated together for years.” Matthew Harding, the Owner of Cuna
              </i></div>
              <div class="desc">Get the latest offerings and services from our team by contacting us today! We are excited to learn more about how we can help you.
              </div>
            </div>

            <!-- post 10 -->
            <div
              class="text"
              v-if="activeBlog.url == 'how-to-find-a-ux-ui-design-agency'"
            >
              <img alt="Uvio" src="./../assets/post10/1239.jpg" />
              <div class="desc">As the digital world continues to expand, the demand for top-quality UX/UI design has never been greater. With so many design agencies to choose from, it can be overwhelming to determine which one is the right fit for your business. At UVIO, we understand the importance of finding the perfect UX/UI design agency, and we're here to help you navigate the process. Here are some key factors to consider when searching for a proper UX/UI design agency:
              </div>
              <img alt="Uvio" src="./../assets/post10/1240.jpg" />
              <h2 class="title-small">Portfolio and Case Studies</h2>
              <div class="desc">One of the best ways to evaluate an agency's expertise is to review their portfolio and case studies. These materials give you a glimpse into the types of projects the agency has worked on and the results they have achieved. Pay close attention to their approach and how they tackled each challenge. Look for examples of work that aligns with your industry and goals.
              </div>
              <img alt="Uvio" src="./../assets/post10/1241.jpg" />
              <h2 class="title-small">Reviews and Testimonials</h2>
              <div class="desc">Check out the agency's website, Clutch, and other review platforms to see what clients have to say about their experience working with the agency. Look for feedback on the agency's communication, responsiveness, and ability to deliver quality work on time and within budget.
              </div>
              <h2 class="title-small">Expertise and Specialization</h2>
              <div class="desc">Make sure the agency you choose has experience and expertise in the areas you need. While many agencies offer a wide range of services, it's essential to find an agency that specializes in UX/UI design. Ask about the agency's process and how they approach design challenges. Make sure they understand your industry and target audience.
              </div>
              <h2 class="title-small">Communication and Collaboration</h2>
              <div class="desc">Communication is key when working with any agency. Make sure the agency you choose has a clear and effective communication process in place. Ask about their collaboration tools and how they keep clients informed throughout the design process. Look for an agency that values feedback and collaboration.
              </div>
              <h2 class="title-small">Pricing and Budget</h2>
              <div class="desc">Budget is always a consideration when choosing an agency. Be upfront about your budget and expectations from the beginning. Ask for a detailed breakdown of the agency's pricing structure and what is included in each package. Remember that quality work comes at a cost, so be prepared to invest in an agency that will deliver the results you need.
              </div>
              <div class="desc">In conclusion, finding a proper UX/UI design agency can be a daunting task, but it's worth the effort to ensure your digital presence stands out in a crowded marketplace. At UVIO, we specialize in creating innovative and effective designs that meet the unique needs of each of our clients. Contact us today to learn more about how we can help you elevate your digital presence.
              </div>
            </div>

            <!-- post 11 -->
            <div
              class="text"
              v-if="
                activeBlog.url == 'manifest-most-reviewed-web-developers-latvia'
              "
            >
              <img
                alt="Manifest Most Reviewed Web Developers Latvia"
                src="./../assets/post11/web-developer-latvia.jpg"
              />
              <div class="desc">
                By helping companies to have a strong online presence, engage
                with audiences around the world, and provide seamless user
                experiences, web development is fundamentally changing how
                organizations operate. E-commerce is being made easier,
                processes are being streamlined through automation, and
                interactive platforms are increasing client involvement.
              </div>
              <div class="desc">
                Get to know more about the web development industry with the
                help of UVIO! Senior UX and UI designers, developers, and other
                professionals on UVIO's interdisciplinary team swiftly create
                user interfaces and experiences that don't compromise either
                appearance or functionality. Our designs are functional and
                aesthetically pleasing, and they incorporate your brand's
                narrative into the very fabric of what we do.
              </div>
              <div class="desc">
                Today, our team is proud to announce that we’ve been recently
                named one of the most-reviewed web developers in Latvia by none
                other than The Manifest! We are honored to be part of this
                incredible list and to be singled out as one of the best B2B
                service providers in the local market.
              </div>
              <div class="desc">
                The Manifest, for those of you who don’t know, is
                <a
                  href="http://themanifest.com/lv/web-development/companies?utm_source=directory&utm_medium=blog&utm_campaign=TM+Awards"
                  target="_blank"
                  >a business blog platform</a
                >
                that aims to gather and verify the hard data, expert insights,
                and actionable advice that you need to build your brand and grow
                your business – to provide the practical business wisdom that
                manifests in your success.
              </div>
              <div class="desc">
                Lastly, we would like to express our gratitude to our clients
                who played a vital role in helping us secure this amazing
                accolade. Thank you so much for trusting us with your business
                and for believing in our team.
              </div>
              <div class="desc">
                <a
                  href="https://calendly.com/uvio-design/30min?month=2023-08"
                  target="_blank"
                  >Book a call</a
                >
                today! We'll have a quick intro call with a friendly chat and a
                short presentation about the UVIO team.
              </div>
            </div>
          </div>
          <div class="button-book-container">
            <div
              class="button-book book-a-call-btn"
              @click="$emit('openShareModal')"
            >
              <div class="button-book-wrapper">
                <span>Share</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './../assets/style/posts.css';

export default {
  name: 'Post',
  components: {
  },
  props: ['activeBlog'],
  data: function () {
    return {};
  },
  metaInfo: function () {
    return {
      title: this.activeBlog.title,
      meta: [
        { 'property': 'description', 'content': this.truncate(this.activeBlog.desc, 160) + '...', 'vmid': 'description' },
        { 'property': 'og:description', 'content': this.truncate(this.activeBlog.desc, 160) + '...', 'vmid': 'og:description' },
        { 'property': 'og:title', 'content': this.activeBlog.title, 'vmid': 'og:title' },
        { 'property': 'og:site_name', 'content': 'UVIO, Carefully devised digital products that strengthen your brand', 'vmid': 'og:site_name' },
        { 'property': 'og:type', 'content': 'website', 'vmid': 'og:type' },
        { 'property': 'og:image', 'content': 'https://www.uvio.co/uvio/lead-preview.png', 'vmid': 'og:image' },
        { 'property': 'og:url', 'content': 'https://www.uvio.co/' + this.activeBlog.url, 'vmid': 'og:url' },
        { 'name': 'robots', 'content': 'index,follow', 'vmid': 'robots' },
        { 'name': 'twitter:card', 'content': 'summary_large_image', 'vmid': 'twitter:card' },
        { 'name': 'twitter:title', 'content': this.activeBlog.title, 'vmid': 'twitter:title' },
        { 'name': 'twitter:description', 'content': this.truncate(this.activeBlog.desc, 160) + '...', 'vmid': 'twitter:description' },
        { 'name': 'twitter:image', 'content': 'https://www.uvio.co/uvio/lead-preview.png', 'vmid': 'twitter:image' },
        { 'name': 'twitter:site', 'content': '@uvio_co', 'vmid': 'twitter:site' },
        { 'name': 'twitter:creator', 'content': '@uvio_co', 'vmid': 'twitter:creator' },
        { 'name': 'twitter:domain', 'content': 'https://www.uvio.co/' + this.activeBlog.url, 'vmid': 'twitter:domain' },
        { 'name': 'twitter:app:name:iphone', 'content': 'UVIO', 'vmid': 'twitter:app:name:iphone' },
        { 'name': 'twitter:app:name:ipad', 'content': 'UVIO', 'vmid': 'twitter:app:name:ipad' },
        { 'name': 'twitter:app:name:googleplay', 'content': 'UVIO', 'vmid': 'twitter:app:name:googleplay' },
        { 'name': 'twitter:app:id:iphone', 'content': 'id1510000000', 'vmid': 'twitter:app:id:iphone' },
        { 'name': 'twitter:app:id:ipad', 'content': 'id1510000000', 'vmid': 'twitter:app:id:ipad' },
      ],
    };
  },
  created() {},
  watch: {},
  mounted: function () {},
  methods: {
    truncate(str, length) {
      if (str.length > length) {
        return str.slice(0, length) + '...';
      } else return str;
    }
  },
};
</script>
