<template>
  <form class="form" ref="contactForm" @submit.prevent="submitFunc">
    <transition name="fade">
      <div class="success" v-if="formSucceed">
        Great! Our journey just begun.
      </div>
    </transition>
    <img class="img blue" alt="UVIO contact" v-lazy="'/uvio/blue.png'" />
    <img class="img red" alt="UVIO contact" v-lazy="'/uvio/red.png'" />
    <h3 class="title">We will make it work. Together. Just send us a note</h3>
    <div class="form-wrapper" id="contactFormWrapper">
      <input
        type="text"
        name="name"
        class="form-input"
        placeholder="Name"
        v-model="name"
      />
      <input
        type="email"
        name="email"
        class="form-input"
        placeholder="Email Address"
        v-model="email"
      />
      <input
        type="phone"
        name="phone"
        class="form-input"
        placeholder="Phone number"
        v-model="phoneNumber"
      />
      <input
        type="text"
        name="company"
        class="form-input"
        placeholder="Company"
        v-model="company"
      />
      <input
        type="text"
        name="message"
        class="form-input"
        placeholder="Message"
        v-model="message"
      />
    </div>
    <div class="errors" v-if="errors.length">
      <div class="item desc" v-for="(item, i) in errors" :key="i">
        {{ item }}
      </div>
    </div>
    <div class="button-container">
      <button
        class="form-submit-button"
        id="contact-form-submit-button"
        type="submit"
      >
        <div id="circle2" class="circle-container">
          <img
            class="circle img"
            alt="UVIO contact"
            v-lazy="'/uvio/circle.png'"
          />
        </div>
        <span>SEND</span>
      </button>
    </div>
  </form>
</template>

<script>
import emailjs from '@emailjs/browser';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'ContactForm',
  data: function () {
    return {
      formSucceed: false,
      name: '',
      email: '',
      phoneNumber: '',
      company: '',
      message: '',
      errors: [],
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
    },
    phoneNumber: {
      required,
    },
    company: {
      required,
    },
    message: {
      required,
    },
  },
  created() {},
  watch: {},
  mounted: function () {},
  methods: {
    submitFunc() {
      window.dispatchEvent(new Event('resize'));
      this.$v.$touch();

      if (this.$v.$invalid) {
        // console.log(this.$v);
        // let errors = '<ul>';
        this.errors = [];

        if (!this.$v.company.required) {
          // errors = errors + '<li>Company field is required</li>';
          this.errors.push('Company field is required');
        }
        if (!this.$v.email.required) {
          // errors = errors + '<li>Email field is required</li>';
          this.errors.push('Email field is required');
        }
        if (!this.$v.message.required) {
          // errors = errors + '<li>Message field is required</li>';
          this.errors.push('Message field is required');
        }
        if (!this.$v.name.required) {
          // errors = errors + '<li>Name field is required</li>';
          this.errors.push('Name field is required');
        }
        if (!this.$v.phoneNumber.required) {
          // errors = errors + '<li>Phone number field is required</li>';
          this.errors.push('Phone number field is required');
        }

        // errors = errors + '</ul>'

        // this.$notify({
        //   group: 'foo',
        //   text: errors,
        //   position: 'bottom-right',
        //   time: 10000
        // });
      } else {
        emailjs
          .sendForm(
            'service_zdwfihx',
            'template_93u8aj6',
            this.$refs.contactForm,
            '4sPLypGzj7D0PKWkr'
          )
          .then(
            (result) => {
              console.log('SUCCESS!', result.text);
              this.formSucceed = true;
            },
            (error) => {
              console.log('FAILED...', error.text);
            }
          );
      }
    },
  },
};
</script>
