<template>
  <div class="modal download-pdf-modal">
    <div class="modal-form">
      <div class="close-button" @click="$emit('closeShareModal')"><div class="close-icon"><span></span><span></span></div>Close</div>
      <div class="modal-form-body">
        <section class="dark-section">
          <div class="dark-section-wrapper">
            <img class="img blue" alt="UVIO team" v-lazy="'/uvio/blue.png'" />
            <img class="img red" alt="UVIO team" v-lazy="'/uvio/red.png'" />
            <div class="dark-section-inside">
              <div class="form-wrap">
                <form :class="['form', { succeeded: formSucceed }]" ref="contactForm" @submit.prevent="submitFunc">
                  <transition name="fade">
                    <div class="success" v-if="formSucceed">
                      Great! Our journey just begun.
                    </div>
                  </transition>
                  <div class="form-title">
                    <h2>Enter your details below to download PDF</h2>
                  </div>
                  <div class="form-title form-title-success">
                    <h2>Thank you for downloading!</h2>
                  </div>
                  <div class="form-subtitle form-subtitle-success">
                    <p>You will now receive the download link to your email address</p>
                  </div>
                  <div class="form-wrapper" id="contactFormWrapper">
                    <input
                      type="text"
                      name="name"
                      class="form-input"
                      placeholder="Name"
                      v-model="name"
                    />
                    <input
                      type="email"
                      name="email"
                      class="form-input"
                      placeholder="Email Address"
                      v-model="email"
                    />
                  </div>
                  <div class="errors" v-if="errors.length">
                    <div class="item desc" v-for="(item, i) in errors" :key="i">
                      {{ item }}
                    </div>
                  </div>
                  <div class="button-container">
                    <button
                      class="form-submit-button"
                      id="contact-form-submit-button"
                      type="submit"
                    >
                      <div id="circle2" class="circle-container">
                        <img
                          class="circle img"
                          alt="UVIO contact"
                          v-lazy="'/uvio/circle.png'"
                        />
                      </div>
                      <span v-if="!formLoading">DOWNLOAD</span>
                      <span class="btn-loader" v-if="formLoading"><div class="loader-1 center"><span></span></div></span>
                    </button>
                    <button
                      class="form-submit-button"
                      id="contact-form-submit-success-button"
                      @click="goToHomePage"
                    >
                      <div id="circle2" class="circle-container">
                        <img
                          class="circle img"
                          alt="UVIO contact"
                          v-lazy="'/uvio/circle.png'"
                        />
                      </div>
                      <span>UVIO HOME PAGE</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import emailjs from '@emailjs/browser';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'DownloadPDFModal',
  props: ['title', 'description'],
  components: {},
  created() {},
  data() {
    return {
      copyMessageVisible: false,
      formSucceed: false,
      formLoading: false,
      name: '',
      email: '',
      errors: [],
    };
  },
  validations: {
    name: {
      required,
    },
    email: {
      required,
    },
  },
  mounted() {},
  computed: {},
  methods: {
    onCopy() {
      let self = this;
      self.copyMessageVisible = true;
      setTimeout(function () {
        self.copyMessageVisible = false;
      }, 2000);
    },
    fullPath() {
      return window.location.href;
    },
    goToHomePage() {
      this.$router.push('/');
    },
    submitFunc() {
      if (this.formLoading) {
        return;
      }

      this.formLoading = true;
      window.dispatchEvent(new Event('resize'));
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.formLoading = false;
        // console.log(this.$v);
        // let errors = '<ul>';
        this.errors = [];

        if (!this.$v.email.required) {
          // errors = errors + '<li>Email field is required</li>';
          this.errors.push('Email field is required');
        }
        if (!this.$v.name.required) {
          // errors = errors + '<li>Name field is required</li>';
          this.errors.push('Name field is required');
        }

        // errors = errors + '</ul>'

        // this.$notify({
        //   group: 'foo',
        //   text: errors,
        //   position: 'bottom-right',
        //   time: 10000
        // });
      } else {
        emailjs
          .sendForm(
            'service_zdwfihx',
            'template_93u8aj6',
            this.$refs.contactForm,
            '4sPLypGzj7D0PKWkr'
          )
          .then(
            (result) => {
              console.log('SUCCESS!', result.text);
              this.formSucceed = true;
            },
            (error) => {
              console.log('FAILED...', error.text);
            }
          );

        emailjs
          .sendForm(
            'service_zdwfihx',
            'template_7uyq63k',
            this.$refs.contactForm,
            '4sPLypGzj7D0PKWkr'
          )
          .then(
            (result) => {
              console.log('SUCCESS!', result.text);
              this.formSucceed = true;
              this.formLoading = false;
            },
            (error) => {
              console.log('FAILED...', error.text);
              this.formLoading = false;
            }
          );
      }
    },
  },
  filters: {},
};
</script>
<style lang="scss"></style>
