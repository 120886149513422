<template>
  <div id="app">
    <div class="page post-page page-not-found">
      <div class="page-wrapper">
        <header class="header">
          <div class="header-wrapper visible">
            <a class="header-logo" href="https://www.uvio.co/">
              <img
                class="img desk"
                alt="UVIO"
                src="./../assets/post/logo.svg"
              />
              <img
                class="img mob"
                alt="UVIO"
                src="./../assets/post/logoMob.svg"
              />
            </a>
            <div class="right right-fixed">
              <a
                href="https://calendly.com/uvio-design/30min"
                target="__blank"
                id="book-a-call-btn"
                alt="Book a call with UVIO"
                class="button-book book-a-call-btn"
              >
                <div class="button-book-wrapper">
                  <span>Book a Call</span>
                  <img
                    class="img"
                    alt="Calendar"
                    src="./../assets/post/calendar.svg"
                  />
                </div>
              </a>
            </div>
          </div>
        </header>
        <main class="main">
          <section class="dark-section">
            <div class="dark-section-wrapper">
              <img class="img blue" alt="UVIO team" v-lazy="'/uvio/blue.png'" />
              <img class="img red" alt="UVIO team" v-lazy="'/uvio/red.png'" />
              <div class="dark-section-inside">
                <div class="glitch-container">
                  <div class="container">
                    <div class="glitch" data-text="4  0  4">4 0 4</div>
                    <div class="glow">4 0 4</div>
                  </div>
                  <div class="scanlines"></div>
                </div>

                <div class="desc">You know what does it mean...</div>
                <a href="https://www.uvio.co/" class="button">HOME PAGE</a>
              </div>
            </div>
          </section>
        </main>
        <div class="footer">
          <div class="footer-bottom">
            <div class="left">
              <div class="title-small">hello@uvio.co</div>
              <div class="desc">
                <span class="gray">Office:</span>
                <span>16192 Coastal Highway, Lewes, Delaware 19958 USA</span>
              </div>
            </div>
            <div class="right">
              <ul class="list">
                <li class="item">
                  <a
                    href="https://www.linkedin.com/company/uvio-design"
                    target="__blank"
                    class="link"
                    ><img
                      src="./../assets/post/linkedIn.svg"
                      alt="LinkedIn"
                      class="img"
                  /></a>
                </li>
                <li class="item">
                  <a
                    href="https://dribbble.com/uvio"
                    target="__blank"
                    class="link"
                    ><img
                      src="./../assets/post/dribbble.svg"
                      alt="Dribble"
                      class="img"
                  /></a>
                </li>
                <li class="item">
                  <a
                    href="https://www.behance.net/uvioagency"
                    target="__blank"
                    class="link"
                    ><img
                      src="./../assets/post/behance.svg"
                      alt="Behance"
                      class="img"
                  /></a>
                </li>
              </ul>
              <div class="desc">UVIO LLC © 2023</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  components: {},
  data: function () {
    return {};
  },
  metaInfo() {
    return {
      title: 'UVIO, Carefully devised digital products that strengthen your brand',
      meta: [
        { 'property': 'description', 'content': 'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users', 'vmid': 'description' },
        { 'property': 'og:description', 'content': 'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users', 'vmid': 'og:description' },
        { 'property': 'og:title', 'content': 'UVIO, Carefully devised digital products that strengthen your brand', 'vmid': 'og:title' },
        { 'property': 'og:site_name', 'content': 'UVIO, Carefully devised digital products that strengthen your brand', 'vmid': 'og:site_name' },
        { 'property': 'og:type', 'content': 'website', 'vmid': 'og:type' },
        { 'property': 'og:image', 'content': 'https://www.uvio.co/uvio/lead-preview.png', 'vmid': 'og:image' },
        { 'property': 'og:url', 'content': 'https://uvio.co', 'vmid': 'og:url' },
        { 'name': 'robots', 'content': 'index,follow', 'vmid': 'robots' },
        { 'name': 'twitter:card', 'content': 'summary_large_image', 'vmid': 'twitter:card' },
        { 'name': 'twitter:title', 'content': 'UVIO, Carefully devised digital products that strengthen your brand', 'vmid': 'twitter:title' },
        { 'name': 'twitter:description', 'content': 'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users', 'vmid': 'twitter:description' },
        { 'name': 'twitter:image', 'content': 'https://www.uvio.co/uvio/lead-preview.png', 'vmid': 'twitter:image' },
        { 'name': 'twitter:site', 'content': '@uvio_co', 'vmid': 'twitter:site' },
        { 'name': 'twitter:creator', 'content': '@uvio_co', 'vmid': 'twitter:creator' },
        { 'name': 'twitter:domain', 'content': 'https://uvio.co', 'vmid': 'twitter:domain' },
        { 'name': 'twitter:app:name:iphone', 'content': 'UVIO', 'vmid': 'twitter:app:name:iphone' },
        { 'name': 'twitter:app:name:ipad', 'content': 'UVIO', 'vmid': 'twitter:app:name:ipad' },
        { 'name': 'twitter:app:name:googleplay', 'content': 'UVIO', 'vmid': 'twitter:app:name:googleplay' },
        { 'name': 'twitter:app:id:iphone', 'content': 'id1510000000', 'vmid': 'twitter:app:id:iphone' },
        { 'name': 'twitter:app:id:ipad', 'content': 'id1510000000', 'vmid': 'twitter:app:id:ipad' },
      ],
    };
  },
};
</script>
