<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'App',

  mounted: function () {
    // var password;
    // var pass1="cool";
    // password=prompt('Please enter your password to view this page!','');
    // if (password!=pass1) {
    //     window.location="https://uvio.agency";
    // }
    $(window).scrollTop(0);
  },
  metaInfo() {
    return {
      title: 'UVIO, Carefully devised digital products that strengthen your brand',
      meta: [
        { 'property': 'description', 'content': 'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users', 'vmid': 'description' },
        { 'property': 'og:description', 'content': 'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users', 'vmid': 'og:description' },
        { 'property': 'og:title', 'content': 'UVIO, Carefully devised digital products that strengthen your brand', 'vmid': 'og:title' },
        { 'property': 'og:site_name', 'content': 'UVIO, Carefully devised digital products that strengthen your brand', 'vmid': 'og:site_name' },
        { 'property': 'og:type', 'content': 'website', 'vmid': 'og:type' },
        { 'property': 'og:image', 'content': 'https://www.uvio.co/uvio/lead-preview.png', 'vmid': 'og:image' },
        { 'property': 'og:url', 'content': 'https://uvio.co', 'vmid': 'og:url' },
        { 'name': 'robots', 'content': 'index,follow', 'vmid': 'robots' },
        { 'name': 'twitter:card', 'content': 'summary_large_image', 'vmid': 'twitter:card' },
        { 'name': 'twitter:title', 'content': 'UVIO, Carefully devised digital products that strengthen your brand', 'vmid': 'twitter:title' },
        { 'name': 'twitter:description', 'content': 'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users', 'vmid': 'twitter:description' },
        { 'name': 'twitter:image', 'content': 'https://www.uvio.co/uvio/lead-preview.png', 'vmid': 'twitter:image' },
        { 'name': 'twitter:site', 'content': '@uvio_co', 'vmid': 'twitter:site' },
        { 'name': 'twitter:creator', 'content': '@uvio_co', 'vmid': 'twitter:creator' },
        { 'name': 'twitter:domain', 'content': 'https://uvio.co', 'vmid': 'twitter:domain' },
        { 'name': 'twitter:app:name:iphone', 'content': 'UVIO', 'vmid': 'twitter:app:name:iphone' },
        { 'name': 'twitter:app:name:ipad', 'content': 'UVIO', 'vmid': 'twitter:app:name:ipad' },
        { 'name': 'twitter:app:name:googleplay', 'content': 'UVIO', 'vmid': 'twitter:app:name:googleplay' },
        { 'name': 'twitter:app:id:iphone', 'content': 'id1510000000', 'vmid': 'twitter:app:id:iphone' },
        { 'name': 'twitter:app:id:ipad', 'content': 'id1510000000', 'vmid': 'twitter:app:id:ipad' },
      ],
    };
  },
  data: function () {
    return {};
  },
  watch: {},
};
</script>
