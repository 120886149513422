<template>
  <div>
    <header v-if="!isHome" :class="[{ active: scrollPosition > 10 }, 'header']">
      <div :class="['header-wrapper', { visible: visibleButton }]">
        <div
          :class="['header-logo', { white: darkBgVisible }]"
          v-scroll-to="{ el: '.page', duration: 1500 }"
          @click="goToHome"
        >
          <img class="img desk" alt="UVIO" v-lazy="'/uvio/logo.svg'" />
          <img class="img mob" alt="UVIO" v-lazy="'/uvio/logoMob.svg'" />
        </div>
        <div class="rating">
          <img alt="Rate icon" src="./../assets/rate-icon.svg" class="img" /><span
            class="red"
            ><b>4.9</b></span
          ><span>Rated Creative Agency</span>
        </div>
      </div>
    </header>
    <transition name="fade">
      <div class="menu" v-if="menuIsOpen">
        <div class="menu-top">
          <div class="menu-logo">
            <img class="img desk" alt="UVIO" v-lazy="'/uvio/logo.svg'" />
            <img class="img mob" alt="UVIO" v-lazy="'/uvio/logoMob.svg'" />
          </div>
          <div class="menu-right">
            <button @click="scrollToForm" class="button">Message Us</button>
            <button @click="book" class="button">Book a Call</button>
          </div>
        </div>
        <div class="menu-center">
          <transition name="slideUp">
            <div class="nav">
              <div
                :class="['item', { visible: menuLinkIsVisible }]"
                @click="scrollToTop"
              >
                Top
              </div>
              <div
                :class="['item', { visible: menuLinkIsVisible1 }]"
                @click="scrollToAbout"
              >
                About
              </div>
              <div
                :class="['item', { visible: menuLinkIsVisible2 }]"
                @click="expertiseScroll"
              >
                Expertise
              </div>
              <div
                :class="['item', { visible: menuLinkIsVisible3 }]"
                @click="superpowersScroll"
              >
                Superpowers
              </div>
              <div
                :class="['item', { visible: menuLinkIsVisible4 }]"
                @click="caseStudiesScroll"
              >
                Case Studies
              </div>
              <div
                :class="['item', { visible: menuLinkIsVisible5 }]"
                @click="reviewsScroll"
              >
                Reviews
              </div>
              <div
                :class="['item', { visible: menuLinkIsVisible6 }]"
                @click="blogScroll"
              >
                Blog
              </div>
            </div>
          </transition>
        </div>
        <div :class="['footer-bottom', { visible: menuLinkIsVisible6 }]">
          <div class="left">
            <div class="title-small">hello@uvio.co</div>
            <div class="desc">
              <span class="gray">Office:</span>
              <span>16192 Coastal Highway, Lewes, Delaware 19958 USA</span>
            </div>
          </div>
          <div class="right">
            <ul class="list">
              <li class="item" v-for="(item, i) in socials" :key="i">
                <a :href="item.href" target="__blank" class="link">
                  <!-- {{ item.img }} -->
                  <img
                    class="img"
                    alt="UVIO social"
                    v-lazy="require(`./../assets/socials/${item.img}.svg`)"
                  />
                </a>
              </li>
            </ul>
            <div class="desc">UVIO LLC © 2023</div>
          </div>
          <div class="menu-right">
            <button @click="scrollToForm" class="button">Message Us</button>
            <button @click="book" class="button">Book a Call</button>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div class="uvio-loader new" v-if="scrollLoaderLogo">
        <img class="img logo-sign" alt="UVIO" src="./../assets/logo-sign.png" />
        <img class="img logo-red" alt="UVIO" src="./../assets/logo-red.png" />
        <img class="img logo-blue" alt="UVIO" src="./../assets/logo-blue.png" />
      </div>
    </transition>
    <!-- <div :class="['menu-button-container raise', { visible: visibleButton }]">
      <button
        :class="['menu-button', { active: menuIsOpen }]"
        @click="toggleMenu"
      >
        <div class="menu-icon">
          <span></span>
          <span></span>
        </div>
        <span class="desc">{{ !menuIsOpen ? 'Menu' : 'Close' }}</span>
      </button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'CustomHeader',
  props: ['isHome'],
  data: () => ({
    scrollPosition: 0,
    menuIsOpen: false,
    visibleButton: false,
    scrollLoader: false,
    scrollLoaderWork: false,
    scrollLoaderLogo: false,
    pageLoaded: false,
    menuLinkIsVisible: false,
    menuLinkIsVisible1: false,
    menuLinkIsVisible2: false,
    menuLinkIsVisible3: false,
    menuLinkIsVisible4: false,
    menuLinkIsVisible5: false,
    menuLinkIsVisible6: false,
    menuLinkIsVisible7: false,
    darkBgVisible: false,
    socials: [
      {
        img: 'linkedIn',
        href: 'https://www.linkedin.com/company/uvio-design',
      },
      // {
      //   'img': 'instagram',
      //   'href': '#'
      // },
      {
        img: 'dribbble',
        href: 'https://dribbble.com/uvio',
      },
      {
        img: 'facebook',
        href: 'https://www.facebook.com/uvioagency',
      },
      {
        img: 'behance',
        href: 'https://www.behance.net/uvioagency',
      },
    ],
  }),
  methods: {
    goToConversions() {
      this.$router.push('/lead-gen');
    },
    goToHome() {
      this.$router.push('/');
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      if (this.lastScroll <= this.scrollPosition) {
        this.lastScroll = this.scrollPosition;
        this.scrollDirectionUp = false;
      } else {
        this.lastScroll = this.scrollPosition;
        this.scrollDirectionUp = true;
      }
    },
    toggleMenu() {
      this.menuIsOpen = !this.menuIsOpen;

      setTimeout(() => {
        this.menuLinkIsVisible = !this.menuLinkIsVisible;
      }, 100);
      setTimeout(() => {
        this.menuLinkIsVisible1 = !this.menuLinkIsVisible1;
      }, 200);
      setTimeout(() => {
        this.menuLinkIsVisible2 = !this.menuLinkIsVisible2;
      }, 300);
      setTimeout(() => {
        this.menuLinkIsVisible3 = !this.menuLinkIsVisible3;
      }, 400);
      setTimeout(() => {
        this.menuLinkIsVisible4 = !this.menuLinkIsVisible4;
      }, 500);
      setTimeout(() => {
        this.menuLinkIsVisible5 = !this.menuLinkIsVisible5;
      }, 600);
      setTimeout(() => {
        this.menuLinkIsVisible6 = !this.menuLinkIsVisible6;
      }, 700);
      setTimeout(() => {
        this.menuLinkIsVisible7 = !this.menuLinkIsVisible7;
      }, 800);
    },
    scrollToTop() {
      this.toggleMenu();
      this.$scrollTo('.header', {
        duration: 2500,
        easing: 'cubic-bezier(.01,.23,0,.98)',
      });
    },
    scrollToAbout() {
      if (!this.isHome) {
        this.$router.push({ path: '/', hash: '#about' });
      } else {
        this.toggleMenu();
        this.$scrollTo('.section-2', {
          duration: 700,
          easing: 'cubic-bezier(.01,.23,0,.98)',
        });
      }
    },
    expertiseScroll() {
      if (!this.isHome) {
        this.$router.push({ path: '/', hash: '#expertise' });
      } else {
        this.toggleMenu();
        let self = this;
        setTimeout(() => {
          self.scrollLoader = true;
          self.scrollLoaderLogo = true;
        }, 200);
        setTimeout(() => {
          self.scrollLoaderLogo = false;
        }, 1600);
        setTimeout(() => {
          self.scrollLoader = false;
        }, 2500);
        this.$scrollTo('.dark-section', {
          duration: 2300,
          easing: 'cubic-bezier(1, 0, 0.5, 1)',
          offset: -100,
        });
      }
    },
    superpowersScroll() {
      if (!this.isHome) {
        this.$router.push({ path: '/', hash: '#superpowers' });
      } else {
        this.toggleMenu();
        let self = this;
        setTimeout(() => {
          self.scrollLoader = true;
          self.scrollLoaderLogo = true;
        }, 200);
        setTimeout(() => {
          self.scrollLoaderLogo = false;
        }, 1600);
        setTimeout(() => {
          self.scrollLoader = false;
        }, 2500);
        this.$scrollTo('.superpower', {
          duration: 2000,
          easing: 'cubic-bezier(1, 0, 0.5, 1)',
          offset: 50,
        });
      }
    },
    caseStudiesScroll() {
      if (!this.isHome) {
        this.$router.push({ path: '/', hash: '#works' });
      } else {
        this.toggleMenu();
        let self = this;
        setTimeout(() => {
          self.scrollLoaderWork = true;
          self.scrollLoaderLogo = true;
        }, 300);
        setTimeout(() => {
          self.scrollLoaderLogo = false;
        }, 2400);
        setTimeout(() => {
          self.scrollLoaderWork = false;
        }, 3500);
        this.$scrollTo('.works', {
          duration: 2500,
          easing: 'cubic-bezier(1, 0, 0.5, 1)',
          offset: -100,
        });
      }
    },
    reviewsScroll() {
      if (!this.isHome) {
        this.$router.push({ path: '/', hash: '#reviews' });
      } else {
        this.toggleMenu();
        let self = this;
        setTimeout(() => {
          self.scrollLoader = true;
          self.scrollLoaderLogo = true;
        }, 200);
        setTimeout(() => {
          self.scrollLoaderLogo = false;
        }, 1600);
        setTimeout(() => {
          self.scrollLoader = false;
        }, 2500);
        this.$scrollTo('.reviews', {
          duration: 2200,
          easing: 'cubic-bezier(1, 0, 0.5, 1)',
          offset: 50,
        });
      }
    },
    blogScroll() {
      if (!this.isHome) {
        this.$router.push({ path: '/', hash: '#blog' });
      } else {
        this.toggleMenu();
        let self = this;
        setTimeout(() => {
          self.scrollLoader = true;
          self.scrollLoaderLogo = true;
        }, 200);
        setTimeout(() => {
          self.scrollLoaderLogo = false;
        }, 1900);
        setTimeout(() => {
          self.scrollLoader = false;
        }, 2500);
        this.$scrollTo('.blog-section', {
          duration: 2000,
          easing: 'cubic-bezier(1, 0, 0.5, 1)',
          offset: -50,
        });
      }
    },
    scrollToForm() {
      this.toggleMenu();
      this.$scrollTo('.footer', {
        duration: 2500,
        easing: 'cubic-bezier(.01,.23,0,.98)',
      });
    },
    book() {
      window.open(
        'https://calendly.com/uvio-design/30min',
        '_blank' // <- This is what makes it open in a new window.
      );
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.updateScroll, { passive: true });

      console.log(this.$route.hash);

      if (this.$route.hash === '#about') {
        this.$scrollTo('.section-2', {
          duration: 700,
          easing: 'cubic-bezier(.01,.23,0,.98)',
        });
      }
      if (this.$route.hash === '#expertise') {
        let self = this;
        setTimeout(() => {
          self.scrollLoader = true;
          self.scrollLoaderLogo = true;
        }, 200);
        setTimeout(() => {
          self.scrollLoaderLogo = false;
        }, 1600);
        setTimeout(() => {
          self.scrollLoader = false;
        }, 2500);
        this.$scrollTo('.dark-section', {
          duration: 2300,
          easing: 'cubic-bezier(1, 0, 0.5, 1)',
          offset: -100,
        });
      }
      if (this.$route.hash === '#superpowers') {
        let self = this;
        setTimeout(() => {
          self.scrollLoader = true;
          self.scrollLoaderLogo = true;
        }, 200);
        setTimeout(() => {
          self.scrollLoaderLogo = false;
        }, 1600);
        setTimeout(() => {
          self.scrollLoader = false;
        }, 2500);
        this.$scrollTo('.superpower', {
          duration: 2000,
          easing: 'cubic-bezier(1, 0, 0.5, 1)',
          offset: 50,
        });
      }
      if (this.$route.hash === '#works') {
        let self = this;
        setTimeout(() => {
          self.scrollLoaderWork = true;
          self.scrollLoaderLogo = true;
        }, 300);
        setTimeout(() => {
          self.scrollLoaderLogo = false;
        }, 2400);
        setTimeout(() => {
          self.scrollLoaderWork = false;
        }, 3500);
        this.$scrollTo('.works', {
          duration: 2500,
          easing: 'cubic-bezier(1, 0, 0.5, 1)',
          offset: -100,
        });
      }
      if (this.$route.hash === '#reviews') {
        let self = this;
        setTimeout(() => {
          self.scrollLoader = true;
          self.scrollLoaderLogo = true;
        }, 200);
        setTimeout(() => {
          self.scrollLoaderLogo = false;
        }, 1600);
        setTimeout(() => {
          self.scrollLoader = false;
        }, 2500);
        this.$scrollTo('.reviews', {
          duration: 2200,
          easing: 'cubic-bezier(1, 0, 0.5, 1)',
          offset: 50,
        });
      }
      if (this.$route.hash === '#blog') {
        let self = this;
        setTimeout(() => {
          self.scrollLoader = true;
          self.scrollLoaderLogo = true;
        }, 200);
        setTimeout(() => {
          self.scrollLoaderLogo = false;
        }, 1900);
        setTimeout(() => {
          self.scrollLoader = false;
        }, 2500);
        this.$scrollTo('.blog-section', {
          duration: 2000,
          easing: 'cubic-bezier(1, 0, 0.5, 1)',
          offset: -50,
        });
      }
      // setTimeout(function () {
      // this.visibleButton = true;
      // }, 500);
    }, 2000);
  },
  created() {
    setTimeout(() => {
      this.visibleButton = true;
    }, 500);
  },
};
</script>

<style></style>
