<template>
  <div id="app" class="lead-gen-page">
    <custom-header :isHome="false"></custom-header>
    <div :class="['post-page']">
      <div class="page-wrapper">
        <div class="page-content">
          <section id="conversions" :class="['section-conversions']">
            <div class="conversions-wrapper">
              <div class="left">
                <div class="info-wrap mob">
                  <h1 class="conv-heading">
                    How We Increased the Conversion Rate by
                    <span class="red">15 times</span>
                  </h1>
                  <button @click="openDownloadPDFModal" class="conversions-main-btn button mob">
                    <!-- <div
                      id="circle"
                      class="circle-container"
                      style="
                        transform: translate(718px, 512px);
                        margin: -708px 0px 0px -55vw;
                      "
                    >
                      <img
                        alt="UVIO works"
                        class="circle img"
                        data-src="/uvio/circle.png"
                        src="/uvio/circle.png"
                        lazy="loaded"
                      />
                    </div> -->
                    <span>GET FREE PDF</span>
                  </button>
                </div>
                <div class="img-wrap">
                  <img @click="openDownloadPDFModal" class="conv-img" src="./../assets/conversions.jpg" />
                </div>
              </div>
              <div class="right">
                <div class="info-wrap desk">
                  <h1 class="conv-heading">
                    How We Increased the Conversion Rate by
                    <span class="red">15 times</span>
                  </h1>
                  <div class="quote-wrap">
                    <div class="quote-text">
                      <h3 class="conv-sub-heading">What the client said:</h3>
                      <p class="conv-text">
                        UVIO’s work increased the end customer’s sign-ups from
                        1–2 per month to over 30, receiving positive feedback
                        from stakeholders. <br /><br />Their communicative and
                        timely approach fostered a productive collaboration.
                        Overall, they were committed, supportive, and
                        customer-focused.
                      </p>
                    </div>
                    <div class="quote-icon">
                      <img src="./../assets/quote-icon.png" />
                    </div>
                  </div>
                  <button @click="openDownloadPDFModal" class="conversions-main-btn button desk">
                    <span>GET FREE PDF</span>
                  </button>
                </div>
              </div>
            </div>
          </section>
          <div class="footer" id="contactForm" data-aos="fade-us">
            <contact-form></contact-form>
            <div class="footer-bottom">
              <div class="left">
                <div class="title-small">hello@uvio.co</div>
                <div class="desc">
                  <span class="gray">Office:</span>
                  <span>16192 Coastal Highway, Lewes, Delaware 19958 USA</span>
                </div>
              </div>
              <div class="right">
                <ul class="list">
                  <li class="item" v-for="(item, i) in socials" :key="i">
                    <a :href="item.href" target="__blank" class="link">
                      <img
                        class="img"
                        alt="UVIO social"
                        v-lazy="require(`./../assets/socials/${item.img}.svg`)"
                      />
                    </a>
                  </li>
                </ul>
                <div class="desc">UVIO LLC © 2023</div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div
              v-if="downloadPDFModalVisible"
              class="overlay blur-overlay"
              @click="downloadPDFModalVisible = false"
            ></div>
          </transition>
          <transition name="fade">
            <DownloadPDFModal
              @closeShareModal="downloadPDFModalVisible = false"
              v-if="downloadPDFModalVisible"
            />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Aos from 'aos';
import 'aos/dist/aos.css';

import './../assets/style/posts.css';
import ContactForm from '../components/ContactForm.vue';
import CustomHeader from '../components/CustomHeader.vue';
import DownloadPDFModal from './modals/DownloadPDFModal.vue';

export default {
  name: 'Post',
  props: ['activeBlog'],
  components: {
    ContactForm,
    CustomHeader,
    DownloadPDFModal,
  },
  data: function () {
    return {
      socials: [
        {
          img: 'linkedIn',
          href: 'https://www.linkedin.com/company/uvio-design',
        },
        // {
        //   'img': 'instagram',
        //   'href': '#'
        // },
        {
          img: 'dribbble',
          href: 'https://dribbble.com/uvio',
        },
        {
          img: 'facebook',
          href: 'https://www.facebook.com/uvioagency',
        },
        {
          img: 'behance',
          href: 'https://www.behance.net/uvioagency',
        },
      ],
      downloadPDFModalVisible: false,
    };
  },
  metaInfo: {
    title:
      'UVIO, Carefully devised digital products that strengthen your brand',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        vmid: 'og:title',
        property: 'og:title',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        vmid: 'og:site_name',
        property: 'og:site_name',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        vmid: 'og:type',
        property: 'og:type',
        content: 'website',
      },
      {
        vmid: 'og:description',
        property: 'og:description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        vmid: 'og:image',
        property: 'og:image',
        content: 'https://www.uvio.co/uvio/lead-preview.png',
      },
      {
        vmid: 'og:url',
        property: 'og:url',
        content: 'https://uvio.co/downloadkgecase',
      },
      {
        vmid: 'robots',
        name: 'robots',
        content: 'index,follow',
      },
      {
        vmid: 'twitter:card',
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        vmid: 'twitter:title',
        name: 'twitter:title',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
      {
        vmid: 'twitter:description',
        name: 'twitter:description',
        content:
          'Full cycle UX/UI design and development studio that crafts comprehensive digital experiences for the real users',
      },
      {
        vmid: 'twitter:image',
        name: 'twitter:image',
        content: 'https://www.uvio.co/uvio/lead-preview.png',
      },
      {
        vmid: 'twitter:image:alt',
        name: 'twitter:image:alt',
        content:
          'UVIO, Carefully devised digital products that strengthen your brand',
      },
    ],
  },
  created() {
    Aos.init({
      once: true,
    });
  },
  watch: {},
  mounted: function () {
    console.log('Lead gen loaded');
  },
  methods: {
    truncate(str, length) {
      if (str.length > length) {
        return str.slice(0, length) + '...';
      } else return str;
    },
    openDownloadPDFModal() {
      console.log('openDownloadPDFModal');
      this.downloadPDFModalVisible = true;
    },
  },
};
</script>
